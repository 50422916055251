@tailwind base;
@tailwind components;
@tailwind utilities;

.baskerville {
  font-family: "Libre Baskerville", serif;
}
.marcellus {
  font-family: "Marcellus", serif;
}
.mulish {
  font-family: "Mulish", sans-serif;
}
.orbitron {
  font-family: "Orbitron", sans-serif;
}
.thasadith {
  font-family: "Thasadith", sans-serif;
}
.landing-columns {
  grid-template-columns: 7fr 5fr 3fr 5fr;
}
.product-columns {
  grid-template-columns: 2fr 1fr;
}

/* search bar transition */
.peer:focus ~ .telescope-search .fa-magnifying-glass {
  color: white; /* This will change the icon's color when the input is focused */
}
/* purchase item grid */
.purchase-container {
  display: grid;
  grid-template-columns: clamp(5rem, 15vmin, 15rem) 1fr;
}
/* tooltip caret */


body {
  font-family: "Mulish", sans-serif;
  margin: 0px;
}
hr {
  margin: min(3vmin, 1.7rem);
}
input[type="file"] {
  padding: min(2vmin, 1rem);
  font-size: min(2vmin, 1rem);
}

h4 {
  font-size: min(2.5vmin, 1.5rem);
}
/* transitions */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s forwards;
}
